import { PortableText } from "@portabletext/react";
import { ArrowRight, BookOpen, Check, Clock, DollarSign, Eye, Globe, Mail, Network, Users } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import BillableHoursCalculator from "../components/for-lawyers/BillableHoursCalculator";
import { getServicesPage } from "../sanity/lib/sanityClient";

// Configuration constants
const STRIPE_CONFIG = {
  SCRIPT_URL: "https://js.stripe.com/v3/pricing-table.js",
  PUBLISHABLE_KEY: "pk_live_51MEpelAUThQnZI67ndXHzVzaXSDKpQtYgrr2nWUTxw7N4D3rnV3PJt87SxfopSgTn0bCBwZkWNlxeKZwQthDGsq500rtGIjtWu",
  PRICING_TABLE_ID: "prctbl_1QRdXLAUThQnZI67W2nOiu2T",
};

const ServicesPage = () => {
  const [pageData, setPageData] = useState(null);
  const [isTableLoaded, setIsTableLoaded] = useState(false);
  const [error, setError] = useState(null);
  const pricingTableRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;

    const loadPricingTable = async () => {
      try {
        // Load page data
        const data = await getServicesPage();
        if (isMounted) setPageData(data);

        // Create and load script
        const script = document.createElement("script");
        script.src = STRIPE_CONFIG.SCRIPT_URL;
        script.async = true;

        script.onload = () => {
          if (!isMounted) return;

          if (pricingTableRef.current) {
            // Clear existing content
            pricingTableRef.current.innerHTML = "";

            // Create pricing table
            const pricingTable = document.createElement("stripe-pricing-table");
            pricingTable.setAttribute(
              "pricing-table-id",
              STRIPE_CONFIG.PRICING_TABLE_ID
            );
            pricingTable.setAttribute(
              "publishable-key",
              STRIPE_CONFIG.PUBLISHABLE_KEY
            );

            pricingTableRef.current.appendChild(pricingTable);
            setIsTableLoaded(true);
          }
        };

        script.onerror = () => {
          if (isMounted) {
            setError("Failed to load pricing table");
            setIsTableLoaded(false);
          }
        };

        document.body.appendChild(script);
      } catch (err) {
        if (isMounted) {
          console.error("Error loading pricing table:", err);
          setError("An error occurred while loading the pricing table");
          setIsTableLoaded(false);
        }
      }
    };

    loadPricingTable();

    return () => {
      isMounted = false;
      const script = document.querySelector(
        `script[src="${STRIPE_CONFIG.SCRIPT_URL}"]`
      );
      if (script) script.remove();
    };
  }, []);

  if (!pageData) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-16 h-16 border-4 rounded-full border-t-blue-500 animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white">
      <Helmet>
        <title>{pageData.seoTitle || "For Lawyers"}</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16808356865">
        </script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());

            gtag('config', 'AW-16808356865');
          `}
        </script>
        <script>
          {`
            !function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);
            rdt('init', 'a2_g3xz74w8chkg');
            rdt('track', 'PageVisit');
          `}
        </script>
        <meta name="description" content={pageData.seoDescription} />
        {pageData.ogImage && (
          <meta property="og:image" content={pageData.ogImage} />
        )}
        {/* Enhanced CSS for horizontal layout */}
        <style>
          {`
            stripe-pricing-table {
              width: 100% !important;
              max-width: none !important;
            }
            
            stripe-pricing-table > div {
              max-width: none !important;
            }

            @media (min-width: 768px) {
              stripe-pricing-table > div {
                display: flex !important;
                flex-direction: row !important;
                gap: 20px !important;
                justify-content: center !important;
              }

              stripe-pricing-table .stripe-pricing-table--price-table {
                flex: 1 1 0 !important;
                min-width: 0 !important;
                max-width: none !important;
                margin: 0 !important;
              }
            }

            @media (max-width: 767px) {
              stripe-pricing-table > div {
                display: flex !important;
                flex-direction: column !important;
                gap: 20px !important;
              }
            }
          `}
        </style>
      </Helmet>

      <div className="max-w-7xl px-6 py-16 mx-auto">
        {/* Title and Introduction Section */}
        <div className="mb-16 text-center">
          <h1 className="mb-8 text-5xl font-bold text-secondary">For Lawyers</h1>
          <p className="max-w-3xl mx-auto text-xl text-gray-700 leading-relaxed">
            We've built a platform where attorneys can focus on practicing law—not marketing themselves. Join our growing network to connect with clients who are actively seeking your expertise.
          </p>
        </div>

        {/* Why Join Juricon Section */}
        <div className="mb-20">
          <h2 className="text-3xl font-bold text-center text-primary mb-10">Why Join Juricon?</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Benefit 1 */}
            <div className="flex items-start p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-all">
              <div className="flex-shrink-0 mr-5 p-3 bg-primary/10 rounded-full">
                <Globe className="w-8 h-8 text-primary" />
              </div>
              <div>
                <h3 className="text-xl font-bold text-secondary mb-2">Expand Your Reach</h3>
                <p className="text-gray-600">Be part of an exclusive network connecting you directly to qualified clients actively searching for your expertise.</p>
              </div>
            </div>

            {/* Benefit 2 */}
            <div className="flex items-start p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-all">
              <div className="flex-shrink-0 mr-5 p-3 bg-primary/10 rounded-full">
                <DollarSign className="w-8 h-8 text-primary" />
              </div>
              <div>
                <h3 className="text-xl font-bold text-secondary mb-2">Affordable and Valuable</h3>
                <p className="text-gray-600">Your subscription pays for itself with just one new client—8 billed hours at the average rate of $302/hour covers a full year of Juricon.</p>
              </div>
            </div>

            {/* Benefit 3 */}
            <div className="flex items-start p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-all">
              <div className="flex-shrink-0 mr-5 p-3 bg-primary/10 rounded-full">
                <Clock className="w-8 h-8 text-primary" />
              </div>
              <div>
                <h3 className="text-xl font-bold text-secondary mb-2">Save Time</h3>
                <p className="text-gray-600">Spend 2–3 fewer hours each week on business development and marketing so you can focus on practicing law.</p>
              </div>
            </div>

            {/* Benefit 4 */}
            <div className="flex items-start p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-all">
              <div className="flex-shrink-0 mr-5 p-3 bg-primary/10 rounded-full">
                <Users className="w-8 h-8 text-primary" />
              </div>
              <div>
                <h3 className="text-xl font-bold text-secondary mb-2">Transparent Pricing, Clear Clients</h3>
                <p className="text-gray-600">Clients see your hourly rate upfront, meaning fewer wasted inquiries and more matches with people who are the right fit for your services.</p>
              </div>
            </div>
          </div>
        </div>

        {/* Video and Calculator Section - Side by Side on Desktop, Stacked on Mobile */}
        <div className="mb-20">
          <h2 className="text-3xl font-bold text-center text-primary mb-10">See How Juricon Works For You</h2>
          
          <div className="flex flex-col lg:flex-row gap-8">
            {/* Video Column */}
            {pageData.videoUrl && (
              <div className="lg:w-1/2">
                <div className="relative overflow-hidden rounded-lg aspect-video shadow-xl border border-gray-100">
                  <iframe
                    className="absolute inset-0 w-full h-full"
                    src={pageData.videoUrl}
                    title="Service Overview"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            )}
            
            {/* Calculator Column */}
            <div className="lg:w-1/2 flex items-center">
              <BillableHoursCalculator />
            </div>
          </div>
        </div>

        {/* Professional Perks Section */}
        <div className="mb-20 bg-gray-50 py-16 px-8 rounded-xl">
          <h2 className="text-3xl font-bold text-center text-primary mb-10">Professional Perks</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            {/* Perk 1 */}
            <div className="flex items-start">
              <div className="flex-shrink-0 mr-4 p-3 bg-primary/10 rounded-full">
                <BookOpen className="w-7 h-7 text-primary" />
              </div>
              <div>
                <h3 className="text-xl font-bold text-secondary mb-2">Educational Opportunities</h3>
                <p className="text-gray-600">Co-host webinars to showcase your niche expertise.</p>
              </div>
            </div>

            {/* Perk 2 */}
            <div className="flex items-start">
              <div className="flex-shrink-0 mr-4 p-3 bg-primary/10 rounded-full">
                <Network className="w-7 h-7 text-primary" />
              </div>
              <div>
                <h3 className="text-xl font-bold text-secondary mb-2">Networking Events</h3>
                <p className="text-gray-600">Receive exclusive invitations to Juricon-hosted events, connecting you with other top attorneys and potential clients.</p>
              </div>
            </div>

            {/* Perk 3 */}
            <div className="flex items-start">
              <div className="flex-shrink-0 mr-4 p-3 bg-primary/10 rounded-full">
                <Mail className="w-7 h-7 text-primary" />
              </div>
              <div>
                <h3 className="text-xl font-bold text-secondary mb-2">Newsletter Spotlights</h3>
                <p className="text-gray-600">Be featured in our newsletter, sent to thousands of potential clients and collaborators.</p>
              </div>
            </div>

            {/* Perk 4 */}
            <div className="flex items-start">
              <div className="flex-shrink-0 mr-4 p-3 bg-primary/10 rounded-full">
                <Eye className="w-7 h-7 text-primary" />
              </div>
              <div>
                <h3 className="text-xl font-bold text-secondary mb-2">Enhanced Visibility</h3>
                <p className="text-gray-600">Boost your presence without relying on expensive ad campaigns or spending time on self-promotion.</p>
              </div>
            </div>
          </div>
        </div>

        {/* Why Juricon - Platform Advantages */}
        <div className="mb-20">
          <h2 className="text-3xl font-bold text-center text-primary mb-10">Why Juricon?</h2>
          
          <div className="max-w-3xl mx-auto">
            <p className="text-lg text-gray-700 mb-6">We've built a platform where attorneys can focus on practicing law—not marketing themselves.</p>
            
            <ul className="space-y-4">
              {[
                "Every client lead is tailored to your expertise.",
                "No extra fees to connect with clients—your subscription includes unlimited client outreach.",
                "Be part of a growing network that prioritizes professionalism, transparency, and quality."
              ].map((item, index) => (
                <li key={index} className="flex items-start">
                  <div className="flex-shrink-0 mt-1 mr-3">
                    <Check className="w-5 h-5 text-primary" />
                  </div>
                  <p className="text-gray-700">{item}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Testimonials Section */}
        <div className="mb-20">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-10">Don't believe us? Here's what real attorneys say:</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            {/* Testimonial 1 */}
            <div className="bg-white p-8 rounded-lg shadow-lg border border-gray-100">
              <p className="text-gray-600 italic mb-6">
                "I'm genuinely impressed with my Juricon subscription. In the past six months, I've received several inquiries directly from qualified clients who were already looking for my specific expertise. This led to gaining two new clients with minimal effort on my part. Before Juricon, I was spending too much time on marketing and fielding irrelevant calls, which took focus away from my actual practice. Now, I can trust that the clients coming through are the right fit, and I have more time to dedicate to my cases."
              </p>
              <p className="font-bold text-secondary">H.C - Family and Civil Litigation and Appellate Counsel</p>
            </div>
            
            {/* Testimonial 2 */}
            <div className="bg-white p-8 rounded-lg shadow-lg border border-gray-100">
              <p className="text-gray-600 italic mb-6">
                "Juricon is an innovative and convenient app for more clients to find me. Easy for attorneys and potential clients to use. Highly recommend!"
              </p>
              <p className="font-bold text-secondary">R.R - Victim, Civil Rights, Family, Criminal Attorney</p>
            </div>
          </div>
        </div>

        {/* Stripe Pricing Table with header */}
        <div className="mb-20">
          <div className="text-center mb-10">
            <h2 className="text-3xl font-bold text-primary mb-4">Select Your Subscription Plan</h2>
            <p className="text-xl text-gray-600">Transparent pricing for every practice size.</p>
          </div>
          
          {!isTableLoaded && !error && (
            <div className="flex items-center justify-center py-12">
              <div className="w-16 h-16 border-4 rounded-full border-t-blue-500 animate-spin"></div>
            </div>
          )}
          {error && (
            <div className="text-red-500 text-center py-4 bg-red-50 rounded-lg">
              {error}
            </div>
          )}
          <div
            ref={pricingTableRef}
            className="w-full min-h-[600px] flex justify-center items-center"
          />
        </div>

        {/* Enterprise Solutions */}
        <div className="bg-secondary text-white p-10 rounded-xl shadow-lg mb-20">
          <h2 className="text-3xl font-bold mb-6">Maximize Your Firm's Growth with Juricon</h2>
          
          <p className="text-lg mb-6">
            Juricon isn't just for solo practitioners or small firms—our platform helps mid-size and large firms ensure their attorneys are matched with the right clients based on their expertise. By connecting your team with qualified leads, you can reduce time spent on marketing and administrative tasks, allowing your attorneys to focus on what they do best—practicing law.
          </p>
          
          <p className="text-lg mb-8">
            If you're looking to onboard five or more attorneys, contact us today! We'll set up a time to discuss your firm's needs and customize a plan to help you attract the right clients efficiently.
          </p>
          
          <a 
            href="mailto:info@juricon.ai" 
            className="inline-flex items-center bg-primary text-white px-6 py-3 rounded-lg font-semibold hover:bg-primary/80 transition-all"
          >
            <span>Email us now to get started</span>
            <ArrowRight className="ml-2 w-5 h-5" />
          </a>
        </div>

        {/* Final CTA */}
        {pageData.outro && (
          <div className="max-w-3xl mx-auto mt-20 text-center">
            {typeof pageData.outro === "string" ? (
              <p className="text-xl font-medium text-secondary">
                {pageData.outro}
              </p>
            ) : (
              <PortableText
                value={pageData.outro}
                components={{
                  block: {
                    normal: ({ children }) => (
                      <p className="mb-4 text-xl font-medium text-secondary">
                        {children}
                      </p>
                    ),
                  },
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ServicesPage;