import { useState } from "react";

const NewsletterSubscriptionPopup = ({ setShowPopup }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    agreeToTerms: false,
  });
  const [message, setMessage] = useState("");

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, type, value, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // URL-encode form inputs
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  // Handle form submission using Netlify Forms
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.agreeToTerms) {
      setMessage("You must agree to receive communications from JuriCon.ai.");
      return;
    }

    try {
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "newsletter-signup",
          ...formData,
        }),
      });
      setMessage("Thank you for subscribing to our newsletter!");
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        agreeToTerms: false,
      });
      setTimeout(() => setShowPopup(false), 2000);
    } catch (error) {
      console.error("Submission error:", error);
      setMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="p-6 bg-white rounded-lg shadow-lg w-80">
        <h3 className="mb-4 text-xl font-semibold text-center">
          Subscribe to Our Newsletter
        </h3>
        <form
          name="newsletter-signup"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="newsletter-signup" />
          {/* Honeypot field */}
          <p hidden>
            <label>
              Don’t fill this out if you’re human:{" "}
              <input name="bot-field" onChange={handleInputChange} />
            </label>
          </p>

          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleInputChange}
            className="w-full p-2 mb-2 border border-gray-300 rounded-md"
          />
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleInputChange}
            className="w-full p-2 mb-2 border border-gray-300 rounded-md"
          />
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            value={formData.email}
            onChange={handleInputChange}
            className="w-full p-2 mb-4 border border-gray-300 rounded-md"
          />

          {/* Agree to Terms Checkbox */}
          <div className="flex items-center mb-4">
            <input
              type="checkbox"
              id="agreeToTerms"
              name="agreeToTerms"
              checked={formData.agreeToTerms}
              onChange={handleInputChange}
              className="mr-2"
            />
            <label htmlFor="agreeToTerms" className="text-sm text-gray-600">
              I agree to receive newsletter and communications from JuriCon.ai.
            </label>
          </div>

          {/* Display message */}
          {message && (
            <p
              className={`mb-4 text-sm ${
                message.includes("Thank") ? "text-green-600" : "text-red-600"
              }`}
            >
              {message}
            </p>
          )}

          {/* Subscribe Button */}
          <button
            type="submit"
            className="w-full px-4 py-2 text-white rounded-md bg-primary hover:bg-secondary"
          >
            Subscribe
          </button>

          {/* Cancel Button */}
          <button
            type="button"
            onClick={() => setShowPopup(false)}
            className="w-full px-4 py-2 mt-2 text-gray-500 bg-gray-100 rounded-md hover:bg-gray-200"
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewsletterSubscriptionPopup;
