import { Button } from "@mui/material";
import NoDataAvailable from "components/noDataAvailable/NoDataAvailable";
import SearchResultCard from "components/SearchResultProfileComponents/SearchResultCard";
import SearchResultsHeader from "components/SearchResultProfileComponents/SearchResultsHeader";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchFilterOptions } from "services/fetchFilterOptions";
import { resetFilters, updateFilterArrays } from "store/actions/actions";
import { routes } from "utils/appConfig";
import { pageLimit } from "utils/config";
import { serializeParams } from "utils/helperFunctions/helper";
import { useAxios } from "utils/hooks/useAxios";
import Loader from "utils/loader/Loader";

export const data = [
  {
    matchPercentage: 98,
    firstName: "John",
    lastName: "Doe",
    clientImage: "",
    rating: 4,
    practicingLawSince: "2022 - 12",
    ratePerHourMin: 12,
    ratePerHourMax: 25,
    onContingency: 13,
    practiceAreas: [
      "63b739dc34b98056df03cf56",
      "63b739dc34b98056df03cf58",
      "63b739dc34b98056df03cf59",
      "63b739dc34b98056df03cf5a",
      "63b739dc34b98056df03cf5b",
      "63b739dc34b98056df03cf5d",
    ],
    subscription: "PREMIUM",
  },
  {
    matchPercentage: 98,
    firstName: "John",
    lastName: "Doe",
    clientImage: "",
    rating: 4,
    practicingLawSince: "2022 - 12",
    ratePerHourMin: 12,
    ratePerHourMax: 25,
    onContingency: 13,
    practiceAreas: [
      "63b739dc34b98056df03cf56",
      "63b739dc34b98056df03cf58",
      "63b739dc34b98056df03cf59",
      "63b739dc34b98056df03cf5a",
      "63b739dc34b98056df03cf5b",
      "63b739dc34b98056df03cf5d",
    ],
    subscription: "PREMIUM",
  },
  {
    matchPercentage: 98,
    firstName: "John",
    lastName: "Doe",
    clientImage: "",
    rating: 4,
    practicingLawSince: "2022 - 12",
    ratePerHourMin: 12,
    ratePerHourMax: 25,
    onContingency: 13,
    practiceAreas: [
      "63b739dc34b98056df03cf56",
      "63b739dc34b98056df03cf58",
      "63b739dc34b98056df03cf59",
      "63b739dc34b98056df03cf5a",
      "63b739dc34b98056df03cf5b",
      "63b739dc34b98056df03cf5d",
    ],
    subscription: "STANDARD",
  },
  {
    matchPercentage: 98,
    firstName: "John",
    lastName: "Doe",
    clientImage: "",
    rating: 4,
    practicingLawSince: "2022 - 12",
    ratePerHourMin: 12,
    ratePerHourMax: 25,
    onContingency: 13,
    practiceAreas: [
      "63b739dc34b98056df03cf56",
      "63b739dc34b98056df03cf58",
      "63b739dc34b98056df03cf59",
      "63b739dc34b98056df03cf5a",
      "63b739dc34b98056df03cf5b",
      "63b739dc34b98056df03cf5d",
    ],
    subscription: "PREMIUM",
  },
  {
    matchPercentage: 98,
    firstName: "John",
    lastName: "Doe",
    clientImage: "",
    rating: 4,
    practicingLawSince: "2022 - 12",
    ratePerHourMin: 12,
    ratePerHourMax: 25,
    onContingency: 13,
    practiceAreas: [
      "63b739dc34b98056df03cf56",
      "63b739dc34b98056df03cf58",
      "63b739dc34b98056df03cf59",
      "63b739dc34b98056df03cf5a",
      "63b739dc34b98056df03cf5b",
      "63b739dc34b98056df03cf5d",
    ],
    subscription: "STANDARD",
  },
  {
    matchPercentage: 98,
    firstName: "John",
    lastName: "Doe",
    clientImage: "",
    rating: 4,
    practicingLawSince: "2022 - 12",
    ratePerHourMin: 12,
    ratePerHourMax: 25,
    onContingency: 13,
    practiceAreas: [
      "63b739dc34b98056df03cf56",
      "63b739dc34b98056df03cf58",
      "63b739dc34b98056df03cf59",
      "63b739dc34b98056df03cf5a",
      "63b739dc34b98056df03cf5b",
      "63b739dc34b98056df03cf5d",
    ],
    subscription: "STANDARD",
  },
  {
    matchPercentage: 98,
    firstName: "John",
    lastName: "Doe",
    clientImage: "",
    rating: 4,
    practicingLawSince: "2022 - 12",
    ratePerHourMin: 12,
    ratePerHourMax: 25,
    onContingency: 13,
    practiceAreas: [
      "63b739dc34b98056df03cf56",
      "63b739dc34b98056df03cf58",
      "63b739dc34b98056df03cf59",
      "63b739dc34b98056df03cf5a",
      "63b739dc34b98056df03cf5b",
      "63b739dc34b98056df03cf5d",
    ],
    subscription: "PREMIUM",
  },
  {
    matchPercentage: 98,
    firstName: "John",
    lastName: "Doe",
    clientImage: "",
    rating: 4,
    practicingLawSince: "2022 - 12",
    ratePerHourMin: 12,
    ratePerHourMax: 25,
    onContingency: 13,
    practiceAreas: [
      "63b739dc34b98056df03cf56",
      "63b739dc34b98056df03cf58",
      "63b739dc34b98056df03cf59",
      "63b739dc34b98056df03cf5a",
      "63b739dc34b98056df03cf5b",
      "63b739dc34b98056df03cf5d",
    ],
    subscription: "STANDARD",
  },
  {
    matchPercentage: 98,
    firstName: "John",
    lastName: "Doe",
    clientImage: "",
    rating: 4,
    practicingLawSince: "2022 - 12",
    ratePerHourMin: 12,
    ratePerHourMax: 25,
    onContingency: 13,
    practiceAreas: [
      "63b739dc34b98056df03cf56",
      "63b739dc34b98056df03cf58",
      "63b739dc34b98056df03cf59",
      "63b739dc34b98056df03cf5a",
      "63b739dc34b98056df03cf5b",
      "63b739dc34b98056df03cf5d",
    ],
    subscription: "STANDARD",
  },
];

const SearchResults = () => {
  const [results, setResults] = useState([]);
  const [totalItems, setTotalItems] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchId, setSearchId] = useState("");
  const [sortBy, setSortBy] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, callApi } = useAxios();
  const { isLoading: loadMoreLoading, callApi: loadMoreCallApi } = useAxios();
  const { callApi: filtersApi } = useAxios();
  const { state } = useLocation();
  const user = useSelector((state) => state.user);
  const isAuthenticated = user !== null;
  const [mounted, setMounted] = useState(false);
  const selectedSchools = useSelector(
    (state) => state.filtersReducer.filters.selectedSchools
  );
  const selectedLocations = useSelector(
    (state) => state.filtersReducer.filters.selectedLocations
  );
  const selectedLanguages = useSelector(
    (state) => state.filtersReducer.filters.selectedLanguages
  );
  const {
    searchCategoryId,
    searchSubCategoryId,
    searchStateId,
    searchText,
    searchBy,
    searchName = null,
  } = useSelector((state) => state.searchReducer);
  const { states } = useSelector(
      (state) => state.categoriesReducer
  );

  const updateSearchQueries = (data, filter = false) => { 
    setResults(data.data.providers);
    setTotalItems(data.data.totalItems);
    setCurrentPage(data.data.currentPage);
    if (!filter) {
      setSearchId(data.data.searchId); 
    }
  };

  useEffect(() => {
    if (user?.userType === "PROVIDER") navigate("/");
    handleSearch({ loadMore: false });
    dispatch(resetFilters());
  }, [
    user,
    searchCategoryId,
    searchSubCategoryId,
    searchStateId,
    searchText,
    searchBy,
    sortBy,
  ]);
  useEffect(() => {
    if (mounted) {
      handleSearch({ filter: true });
    } else {
      setMounted(true);
    }
  }, [selectedLocations, selectedSchools, selectedLanguages]);
  useEffect(() => {
    if (searchId) {
      fetchFilterOptions(filtersApi, searchId, (data, error) => {
        if (data) {
          dispatch(updateFilterArrays({ ...data }));
        } else if (error) {
          console.log("filters error", error);
        }
      });
    }
  }, [searchId]);

  const handleLoadMoreClick = () => {
    handleSearch({ loadMore: true });
  };

  const handleSearch = ({ loadMore = false, filter = false }) => {
    // send search string to google analytics
    ReactGA.event({
      category: "Click",
      action: "Juricon_Search_Button",
      label: searchText,
    });
    const { search, textSearch, textSearchUnauthenticated } = routes;
    const searchObject = { limit: pageLimit };
    if (sortBy) {
      searchObject.sort = sortBy + user?.postalCode;
    }
    if (state?.searchId || filter)
      searchObject.searchId = state?.searchId ?? searchId;
    if (filter && selectedSchools?.length && selectedSchools != undefined)
      searchObject.filterSchoolName = selectedSchools[0]?.university;
    if (filter && selectedLocations?.length && selectedLocations != undefined)
      searchObject.filterlocationId = selectedLocations[0]?._id;
    if (filter && selectedLanguages?.length && selectedLanguages != undefined)
      searchObject.filterLanguageId = selectedLanguages[0]?._id;

    // Only handling GENERAL_SEARCH now
    if (!!searchText) {
      searchObject.text = searchText;
      if (loadMore) searchObject.page = currentPage + 1;
      (loadMore ? loadMoreCallApi : callApi)(
          isAuthenticated? textSearch.url + serializeParams(searchObject) : textSearchUnauthenticated.url + serializeParams(searchObject),
          isAuthenticated? textSearch.method : textSearchUnauthenticated.method,
          {},
          {
            onSuccess: ({ data }) => {
              if (loadMore) {
                setResults((pre) => [...pre, ...data.data.providers]);
                setCurrentPage(data.data.currentPage);
              } else {
                updateSearchQueries(data);
              }
            },
            turnOffNotification: true,
          }
      );
    }
  };
  return (
    <div className="w-[100%]">
      <SearchResultsHeader
        results={totalItems}
        isLoading={isLoading}
        selectedSchools={selectedSchools}
        selectedLocations={selectedLocations}
        selectedLanguages={selectedLanguages}
        sortBy={sortBy}
        setSorBy={setSortBy}
        isAuthenticated={isAuthenticated}
      />
      {isLoading ? (
        <div className="w-full h-[50vh] flex justify-center items-center">
          <Loader width="50px" height="50px" />
        </div>
      ) : (
        <div className="pb-20">
          <div className="flex flex-row flex-wrap  gap-[38px] w-100 px-2 md:px-12">
            {results.map((provider) => {
              return (
                <SearchResultCard
                  key={provider._id}
                  name={provider.firstName + " " + provider.lastName}
                  subscription={provider.subscription}
                  providerId={provider._id}
                  searchId={searchId}
                  provider={provider}
                  user={user}
                  states={states}
                />
              );
            })}
          </div>
          {!results?.length && user && (
            <div className="font-bold text-2xl h-[25rem] flex justify-center items-center">
              <NoDataAvailable />
            </div>
          )}
          {totalItems > results?.length && (
            <div className="flex justify-center items-center mt-12">
              {loadMoreLoading ? (
                <Loader width="50px" height="50px" />
              ) : (
                <Button onClick={handleLoadMoreClick} fontWeight={600}>
                  Load More
                </Button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchResults;
