import GeneralSearch from "components/search/GeneralSearch";
import { useSelector } from "react-redux";
import NewTopComponent from "./NewTopComponent";

const SearchLayout = ({ children }) => {
  const userType = useSelector((state) => state.user?.userType);

  return (
    <div className="flex flex-col min-h-screen">
      {/* Hero section */}
      <div className="mb-2">
        <NewTopComponent />
      </div>

      {/* Main content container */}
      <div className="flex-grow">
        <div className="max-w-5xl px-4 mx-auto">
          {/* Search Section */}
          <div className="w-full mb-4">
            {/* Search Input */}
            <div className="max-w-2xl mx-auto">
              <GeneralSearch className="w-full search-input" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchLayout;
