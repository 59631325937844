import { cn } from '../utils/cn';
import { GlowEffect } from './ui/glow-effect';

// Icons
import { Briefcase, CheckCircle, MessageSquare, Target } from 'lucide-react';

const steps = [
  {
    step: 1,
    title: "Describe Your Legal Issue",
    description: "Share your legal situation in your own words—no legal jargon required. We make it easy to explain your needs.",
    icon: MessageSquare,
    color: '#00A4DA' // Primary color
  },
  {
    step: 2,
    title: "Get Personalized Matching",
    description: "Our system helps refine your description to ensure it's clear and concise, making it easier to match you with the right legal expertise.",
    icon: Target,
    color: '#0076a0' // Slightly darker primary
  },
  {
    step: 3,
    title: "Find the Right Attorney",
    description: "Browse through our database of hundreds of verified lawyers near you, filtered to match your specific case type and budget requirements.",
    icon: Briefcase,
    color: '#00587A' // Even darker primary
  },
  {
    step: 4,
    title: "Compare and Choose",
    description: "Explore your options, compare attorney profiles, and connect with as many professionals as you need to find your perfect legal match.",
    icon: CheckCircle,
    color: '#002A5C' // Secondary color
  }
];

const HowToUseJuricon = () => {
  return (
    <div className="pt-8 pb-16 px-4">
      <h1 className="text-5xl font-bold text-center text-primary mb-12">
        How to Use Juricon
      </h1>
      
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {steps.map((step, index) => {
          const Icon = step.icon;
          return (
            <div key={step.step} className="relative">
              <div className="absolute inset-0 -z-10">
                <GlowEffect
                  colors={[step.color]}
                  mode="breathe"
                  blur="xl"
                  scale={1.05}
                  duration={5}
                />
              </div>
              <div className={cn(
                "relative z-10",
                "h-full p-6 rounded-lg bg-white/95",
                "border border-white/10 shadow-xl",
                "flex flex-col items-center text-center",
                "transition-all duration-300 hover:scale-105"
              )}>
                <div className="mb-4">
                  <div className="w-16 h-16 rounded-full bg-primary/10 flex items-center justify-center mb-2">
                    <Icon className="w-8 h-8 text-primary" />
                  </div>
                  <span className="text-sm font-medium text-primary/60">Step {step.step}</span>
                </div>
                
                <h3 className="text-xl font-semibold mb-3 text-primary">{step.title}</h3>
                <p className="text-sm text-gray-600">{step.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HowToUseJuricon; 