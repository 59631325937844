import Error from "components/error/Error";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateSearchQuery } from "store/actions/actions";
import { GENERAL_SEARCH } from "utils/constants/constants";

const GeneralSearch = ({ navSearch }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { searchText } = useSelector((state) => state.searchReducer);
  
  // Define charCount state to track input length
  const [charCount, setCharCount] = useState(0);

  const formik = useFormik({
    initialValues: {
      searchText: "",
    },
    validate: (values) => {
      const errors = {};
      if (values.searchText.length < 20) {
        errors.searchText = "Search text must be at least 20 characters.";
      } else if (values.searchText.length > 500) {
        errors.searchText = "Search text must be less than 500 characters.";
      }
      return errors;
    },
    onSubmit: (value) => {
      if (value?.searchText) {
        dispatch(
          updateSearchQuery({
            searchText: value.searchText,
            searchType: GENERAL_SEARCH,
          })
        );
        navigate("/searchresult");
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      searchText,
    });
    setCharCount(searchText.length);
  }, [searchText]);

  const { getFieldProps, handleChange } = formik;

  const handleTextChange = (e) => {
    handleChange(e);
    setCharCount(e.target.value.length);
  };

  return (
    <div
      className={`${
        !navSearch && "mt-6 mb-4"
      } w-full flex flex-col justify-center items-center`}
    >
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col w-[100%] max-w-4xl"
      >
        <textarea
          name="searchText"
          {...getFieldProps("searchText")}
          onChange={handleTextChange}
          className="w-full h-[200px] p-4 text-lg font-semibold text-secondary placeholder-[#c6c7c4c4] border-2 border-[#c6c7c481] rounded-md resize-none"
          placeholder=" Enter your legal problem in your own words and let us match you with the
        ideal lawyer, e.g., 'Looking for a lawyer to negotiate a corporate real estate contract for my bakery in Denver, CO.'"
          rows="5"
        />
        <div className="text-right text-sm text-gray-500">
          {charCount}/500
        </div>
        <div className="mt-2 text-center">
          <button 
            type="submit" 
            onClick={formik.handleSubmit} 
            className="bg-primary hover:bg-primary-dark text-white font-bold py-2 px-6 rounded transition-all transform hover:scale-105 active:scale-95 shadow-lg hover:shadow-xl">
            Click Here to Search
          </button>
        </div>
      </form>
      <Error formik={formik} errorField="searchText" />
    </div>
  );
};

export default GeneralSearch;
