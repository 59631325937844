import { useState } from "react";
import { TextLoop } from "../ui/textRole";

const NewTopComponent = () => {
  // Use only the hardcoded practice areas without fetching
  const practiceAreaTitles = [
    "Family",
    "Business",
    "Criminal",
    "Immigration",
    "Personal Injury",
    "Estate Planning",
    "Real Estate",
    "Employment",
    "Water Rights",
    "Bankruptcy",
    "Tax",
    "Civil Rights",
    "Labor",
    "Trusts",
    "Elder Law",
  ];
  
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <div className="w-full max-w-3xl px-4 pt-8 pb-4 mx-auto text-center hero text-secondary">
      {/* Added a container with fixed height for the main heading */}
      <div className="min-h-[240px] mb-8">
        <h2 className="text-6xl font-bold">
          Are you looking for the ideal{" "}
          <span className="text-primary inline-block min-w-32 overflow-visible">
            <TextLoop
              interval={2} // Change every 3 seconds
              transition={{
                type: 'spring',
                stiffness: 900,
                damping: 80,
                mass: 5,
                duration: 0.8
              }}
              variants={{
                initial: {
                  y: 20,
                  rotateX: 90,
                  opacity: 0,
                  filter: 'blur(4px)',
                },
                animate: {
                  y: 0,
                  rotateX: 0,
                  opacity: 1,
                  filter: 'blur(0px)',
                },
                exit: {
                  y: -20,
                  rotateX: -90,
                  opacity: 0,
                  filter: 'blur(4px)',
                },
              }}
            >
              {practiceAreaTitles.map((title) => (
                <span key={title}>{title}</span>
              ))}
            </TextLoop>
          </span>{" "}
          lawyer?
        </h2>
      </div>
      <h2 className="text-3xl text-gray-500">
      Legal Problems are hard enough. Searching for the right lawyer should not be.
      </h2>
    </div>
  );
};

export default NewTopComponent;
