import { cn } from '../../utils/cn';
import { InfiniteSlider } from '../motion-ui/infinite-slider';

const CARDS = [
        {
          "name": "Alex Martinez",
          "content": "fast and easy"
        },
        {
          "name": "Sarah Johnson",
          "content": "Found a lawyer to help me"
        },
        {
          "name": "Jamal Williams",
          "content": "Surprised with the results"
        },
        {
          "name": "Emily Chen",
          "content": "Easy"
        },
        {
          "name": "Miguel Sanchez",
          "content": "Saw an ad and thought I'd try. Worked really well."
        },
        {
          "name": "Rebecca Taylor",
          "content": "Had a contract I needed reviewed. Tried this after I saw a Reddit ad. Found a lawyer pretty quick. Contract was reviewed and they found some big issues."
        },
        {
          "name": "Thomas Nguyen",
          "content": "Had to find a lawyer for my divorce. I didn't like the idea of having to Google and email or call all of them to find out how much it would cost me. I saw an ad on Google and gave this a try. I found a lawyer quickly within my budget without having to waste a lot of time going back and forth."
        },
        {
          "name": "Aisha Jackson",
          "content": "I had used lawyers in the past - one that had been recommended to me, one I saw a commercial for. I have bad luck with legal issues. So when I saw the google ad that I could find my ideal lawyer and match to some, I thought \"why not\". I was pretty happy with the matching. It was kind of a fun way to have to look for a lawyer. I didn't even need to use all the legal jargon I've picked up over the years!"
        },
        {
          "name": "Daniel Gonzalez",
          "content": "Cool platform"
        },
        {
          "name": "Lindsey Parker",
          "content": "I liked that it was free and I could see some prices of lawyers before contacting them with my problem. I also liked that I could contact as many as I wanted."
        },
        {
          "name": "Raj Patel",
          "content": "Worked well. Found a lawyer I needed."
        },
        {
          "name": "Olivia Thompson",
          "content": "I contacted several lawyers for something urgent, and while not all of them got back to me, the ones that did were great. I had heard from other people that some lawyers won't even call back, so I was nervous about having to call. But with this site, I was able to just contact them from there. It was cool that some of them put their prices too. It made deciding who to contact easier."
        },
        {
          "name": "Marcus Lee",
          "content": "My landlord was threatening eviction and I needed help fast. This site matched me with 3 lawyers in my area. Better than spending hours on Google."
        },
        {
          "name": "Jennifer White",
          "content": "worth a try"
        },
        {
          "name": "Darnell Washington",
          "content": "Needed help with a speeding ticket that could've cost me my license. Found a traffic lawyer in minutes that got it reduced. Much cheaper than I expected too."
        },
        {
          "name": "Sofia Rodriguez",
          "content": "Was skeptical but gave it a shot. Got matched with a lawyer who actually specialized in exactly what I needed. Saved me tons of research time."
        },
        {
          "name": "Kyle Bennett",
          "content": "👍"
        },
        {
          "name": "Naomi Chang",
          "content": "Starting a small business and needed legal help with some contracts. Found a business lawyer who offered a free initial consultation. Really helpful service."
        },
        {
          "name": "Isaiah Brown",
          "content": "Much faster than calling around"
        },
        {
          "name": "Amber Wilson",
          "content": "Not bad. Found someone to help with my custody case in like 10 minutes. Appreciated seeing the prices upfront."
        },
        {
          "name": "Derek Miller",
          "content": "Got rear-ended and wasn't sure if I needed a lawyer. Used this to find someone for a quick consultation. Ended up hiring them for my case. They're handling everything with the insurance company now."
        },
        {
          "name": "Zoe Kim",
          "content": "After getting quoted $400/hr by the first lawyer I called, I tried this site. Found someone just as qualified for half the price. Should've started here."
        },
        {
          "name": "Tyler Davis",
          "content": "simple and straightforward"
        },
        {
          "name": "Maya Gutierrez",
          "content": "My company needed an employment lawyer ASAP for an issue with a former employee. Found one through here who had availability the next day. Problem solved."
        },
        {
          "name": "Ethan Garcia",
          "content": "No time wasted on lawyers who don't even handle the type of case I have. This filtered for exactly what I needed."
        },
        {
          "name": "Rachel Tran",
          "content": "Friend recommended this to me. Solid service."
        },
        {
          "name": "Jordan Evans",
          "content": "Was able to describe my situation in regular English without knowing all the legal terms. Got matched with a lawyer who understood exactly what I needed help with."
        },
        {
          "name": "Mia Lopez",
          "content": "Used it twice now. Would recommend."
        },
        {
          "name": "Brandon Wright",
          "content": "Needed a lawyer for estate planning after my dad passed. Really didn't want to spend hours researching who to contact. This made it so much easier during an already difficult time."
        },
        {
          "name": "Leila Navarro",
          "content": "The free price quotes saved me so much awkward phone time"
        },
        {
          "name": "Connor Murphy",
          "content": "Hate talking on the phone. This let me message lawyers directly. Perfect for me."
        },
        {
          "name": "Jasmine Clark",
          "content": "got what I needed"
        }
];

function InfiniteSliderRow({ reverse = false }) {
  const randomCards = CARDS.sort(() => Math.random() - 0.5);

  return (
    <div
      className='flex w-full overflow-x-hidden [mask-image:linear-gradient(to_right,#000,#000,transparent_0,#000_var(--shadow-size),#000_calc(100%_-_var(--shadow-size)),transparent)]'
      style={{
        '--shadow-size': '100px',
      }}
    >
      <InfiniteSlider
        gap={24}
        speed={50}
        speedOnHover={10}
        direction="horizontal"
        reverse={reverse}
        className="py-4"
      >
        {randomCards.map((card, index) => (
          <div 
            key={`${card.name}-${index}`} 
            className={cn(
              'max-w-64 min-w-[260px] rounded-lg p-5',
              'bg-white border-2 border-primary/20',
              'shadow-md hover:shadow-lg transition-all duration-300',
              'hover:border-primary hover:scale-105'
            )}
          >
            <div className='flex items-center gap-2 mb-3'>
              <div className='h-6 w-6 rounded-full bg-gradient-to-br from-primary to-secondary' />
              <span className='text-base font-semibold text-secondary'>
                {card.name}
              </span>
            </div>
            <p className='text-sm text-textColor leading-relaxed'>
              {card.content}
            </p>
          </div>
        ))}
      </InfiniteSlider>
    </div>
  );
}

export function Testimonial2() {
  return (
    <div className='py-16 sm:py-24 bg-gray/30'>
      <div className='max-w-7xl mx-auto px-6 lg:px-8 mb-8'>
        <h2 className='text-3xl font-bold text-center text-secondary mb-2'>
          Here's what people who have used Juricon have to say
        </h2>
        <p className='text-center text-textColor max-w-2xl mx-auto'>
          Real feedback from people who found their ideal legal match through Juricon
        </p>
      </div>
      <div
        className='mx-auto flex max-w-7xl flex-col space-y-8 px-6 lg:px-8'
        style={{
          '--shadow-size': '200px',
        }}
      >
        <InfiniteSliderRow />
        <InfiniteSliderRow reverse />
      </div>
    </div>
  );
}
