import { ArrowRight, Clock, DollarSign, Globe } from "lucide-react";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../assets/background.png";
import overlay from "../assets/overlay.png";

const HelpBanner = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/services");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      className="relative h-max bg-cover bg-right mb-16 rounded-lg overflow-hidden shadow-xl"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div
        className="h-full bg-cover py-12 px-6 md:px-12"
        style={{ backgroundImage: `url(${overlay})` }}
      >
        <div className="max-w-7xl mx-auto">
          {/* Header Section */}
          <div className="text-center mb-10">
            <h2 className="text-4xl md:text-5xl text-secondary font-bold mb-4 
              transform transition-all duration-500 hover:scale-105">
              Join the Juricon Network
            </h2>
            <p className="text-lg md:text-xl text-gray-700 max-w-3xl mx-auto">
              Join 100+ Colorado lawyers (and growing!) already benefiting from{" "}
              <span 
                onClick={handleNavigation}
                className="text-primary font-semibold cursor-pointer 
                transition-all duration-300 
                hover:text-secondary hover:underline">
                Juricon
              </span>
            </p>
          </div>

          {/* Benefits Flow Section */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-10 mt-8">
            {/* Benefit 1 */}
            <div className="flex items-start p-6 bg-white/90 rounded-lg shadow-md 
              transform transition-all duration-500 hover:translate-y-[-5px] hover:shadow-lg">
              <div className="flex-shrink-0 mr-4 p-3 bg-primary/10 rounded-full">
                <Globe className="w-8 h-8 text-primary" />
              </div>
              <div>
                <h3 className="text-xl font-bold text-secondary mb-2">Expand your reach</h3>
                <p className="text-gray-600">Connect with clients you wouldn't otherwise meet through traditional marketing channels.</p>
              </div>
            </div>

            {/* Benefit 2 */}
            <div className="flex items-start p-6 bg-white/90 rounded-lg shadow-md 
              transform transition-all duration-500 hover:translate-y-[-5px] hover:shadow-lg">
              <div className="flex-shrink-0 mr-4 p-3 bg-primary/10 rounded-full">
                <DollarSign className="w-8 h-8 text-primary" />
              </div>
              <div>
                <h3 className="text-xl font-bold text-secondary mb-2">Affordable and valuable</h3>
                <p className="text-gray-600">High return on investment compared to traditional lawyer marketing tactics.</p>
              </div>
            </div>

            {/* Benefit 3 */}
            <div className="flex items-start p-6 bg-white/90 rounded-lg shadow-md 
              transform transition-all duration-500 hover:translate-y-[-5px] hover:shadow-lg">
              <div className="flex-shrink-0 mr-4 p-3 bg-primary/10 rounded-full">
                <Clock className="w-8 h-8 text-primary" />
              </div>
              <div>
                <h3 className="text-xl font-bold text-secondary mb-2">Save time</h3>
                <p className="text-gray-600">Focus on practicing law instead of spending hours on marketing and client acquisition.</p>
              </div>
            </div>
          </div>

          {/* CTA Button */}
          <div className="text-center mt-10">
            <button
              onClick={handleNavigation}
              className="bg-primary text-white px-8 py-3 rounded-md font-semibold
                shadow-lg hover:bg-secondary hover:scale-105
                transition-all duration-300 flex items-center mx-auto"
            >
              <span>Learn More</span>
              <ArrowRight className="ml-2 w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpBanner;
