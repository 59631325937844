import Error from "components/error/Error";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateSearchQuery } from "store/actions/actions";
import { GENERAL_SEARCH } from "utils/constants/constants";

const NavSearch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const textAreaRef = useRef(null);
  const searchFormRef = useRef(null);
  const [charCount, setCharCount] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  // Formik setup for handling the search submission
  const formik = useFormik({
    initialValues: { searchText: "" },
    validate: (values) => {
      const errors = {};
      if (values.searchText.length < 20) {
        errors.searchText = "Search text must be at least 20 characters.";
      } else if (values.searchText.length > 500) {
        errors.searchText = "Search text must be less than 500 characters.";
      }
      return errors;
    },
    onSubmit: (values) => {
      if (values.searchText.trim()) {
        dispatch(
          updateSearchQuery({
            searchText: values.searchText,
            searchType: GENERAL_SEARCH,
          })
        );
        navigate("/searchresult");
        setIsExpanded(false);
      }
    },
  });

  const adjustHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto"; // Reset height
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`; // Set height based on scroll height
    }
  };

  const handleTextChange = (e) => {
    formik.handleChange(e);
    setCharCount(e.target.value.length);
    adjustHeight();
  };

  const toggleSearchForm = () => {
    setIsExpanded(!isExpanded);
  };

  // Close the expanded search when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchFormRef.current && !searchFormRef.current.contains(event.target) && isExpanded) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isExpanded]);

  // Focus the textarea when expanded
  useEffect(() => {
    if (isExpanded && textAreaRef.current) {
      setTimeout(() => textAreaRef.current.focus(), 100);
    }
  }, [isExpanded]);

  return (
    <div className="flex-grow">
      <div className="relative flex items-center justify-center w-full pr-10">
        {/* Mobile search toggle button - only visible on mobile */}
        <button 
          onClick={toggleSearchForm}
          className="md:hidden flex items-center justify-center p-2 rounded-full hover:bg-gray-100"
          aria-label="Toggle search form"
        >
          <i className="text-lg fa-solid fa-magnifying-glass text-primary"></i>
        </button>

        {/* Desktop search form - always visible on desktop, hidden on mobile when not expanded */}
        <div 
          ref={searchFormRef}
          className={`
            transition-all duration-300 ease-in-out
            md:relative fixed md:opacity-100 md:pointer-events-auto
            ${isExpanded ? 
              'opacity-100 pointer-events-auto inset-0 z-50 bg-white p-4 flex flex-col items-center justify-start pt-16' : 
              'opacity-0 pointer-events-none md:opacity-100 md:pointer-events-auto'
            }
          `}
        >
          {/* Close button for mobile expanded view */}
          {isExpanded && (
            <button 
              onClick={() => setIsExpanded(false)}
              className="absolute top-4 right-4 p-2 md:hidden"
              aria-label="Close search"
            >
              <i className="fa-solid fa-times text-lg text-gray-600"></i>
            </button>
          )}

          <div className={`relative w-full max-w-[500px] flex flex-col ${isExpanded ? 'mt-4' : ''}`}>
            {isExpanded && (
              <h2 className="text-xl font-bold text-secondary mb-4 text-center md:hidden">
                Search for legal help
              </h2>
            )}
            
            <form onSubmit={formik.handleSubmit} className="w-full">
              <div className="relative">
                <textarea
                  ref={textAreaRef}
                  name="searchText"
                  placeholder="Describe your legal issue..."
                  rows={isExpanded ? "4" : "1"}
                  {...formik.getFieldProps("searchText")}
                  onChange={handleTextChange}
                  className={`
                    w-full overflow-y-auto p-2 border border-gray-300 rounded-md resize-none
                    ${isExpanded ? 'h-32' : 'max-h-[calc(36px*5)]'}
                  `}
                  style={{
                    fontSize: "16px",
                    minHeight: isExpanded ? "128px" : "36px",
                    lineHeight: "1.5",
                  }}
                />
                <div className="text-right text-xs text-gray-500 mr-2">
                  {charCount}/500
                </div>
              </div>
              
              {/* Search Button */}
              <div className="flex justify-end mt-1">
                <button
                  type="submit"
                  className="bg-primary hover:bg-primary-dark text-white text-sm font-bold py-1 px-3 rounded transition-all transform hover:scale-105 active:scale-95"
                  aria-label="Search"
                >
                  Search
                </button>
              </div>
            </form>
            <Error formik={formik} errorField="searchText" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavSearch;