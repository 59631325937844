import { useState } from 'react';

// Replace aliased imports with relative paths
const Card = ({ children, className }) => (
  <div className={`border-2 border-blue-100 shadow-lg rounded-lg ${className || ''}`}>{children}</div>
);

const CardHeader = ({ children, className }) => (
  <div className={`bg-blue-50 p-4 rounded-t-lg ${className || ''}`}>{children}</div>
);

const CardTitle = ({ children, className }) => (
  <h3 className={`text-center text-primary text-xl font-bold ${className || ''}`}>{children}</h3>
);

const CardContent = ({ children, className }) => (
  <div className={`p-6 ${className || ''}`}>{children}</div>
);

const Input = ({ className, ...props }) => (
  <input className={`w-full border border-gray-300 rounded p-2 ${className || ''}`} {...props} />
);

const BillableHoursCalculator = () => {
  const [hourlyRate, setHourlyRate] = useState(300);
  const annualSubscriptionCost = 2496; // Annual Juricon subscription cost
  const breakEvenHours = hourlyRate > 0 ? Math.ceil(annualSubscriptionCost / hourlyRate) : 0;
  const potentialEarnings = hourlyRate * breakEvenHours;

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>
          How quickly will your Juricon subscription pay for itself?
        </CardTitle>
      </CardHeader>
      <CardContent className="pt-6">
        <div className="space-y-4">
          <div>
            <label htmlFor="hourlyRate" className="block text-sm font-medium mb-1">
              Your Hourly Rate ($)
            </label>
            <Input
              id="hourlyRate"
              type="number"
              value={hourlyRate}
              onChange={(e) => setHourlyRate(Number(e.target.value))}
              min={1}
            />
          </div>
          
          <div className="bg-gray-50 p-4 rounded-lg mt-4">
            <div className="flex justify-between items-center mb-2">
              <span>Annual Juricon Subscription:</span>
              <span className="font-bold text-primary">${annualSubscriptionCost}</span>
            </div>
            
            <div className="border-t border-gray-300 my-2"></div>
            
            <div className="flex justify-between items-center mb-2">
              <span>Break-Even Hours Needed:</span>
              <span className="font-bold text-primary">{breakEvenHours} hours</span>
            </div>
            
            <div className="flex justify-between items-center mb-2">
              <span>Potential Earnings from These Hours:</span>
              <span className="font-bold text-primary">${potentialEarnings.toLocaleString()}</span>
            </div>
            
            <div className="border-t border-gray-300 my-2"></div>
            
            <div className="text-center mt-4">
              <p className="font-medium">
                At your rate of <span className="text-primary">${hourlyRate}/hour</span>, you only need to bill <span className="text-primary">{breakEvenHours} hours</span> to a new client to cover your entire annual subscription!
              </p>
              {breakEvenHours <= 10 && (
                <p className="text-sm text-gray-600 mt-2">
                  That's just {Math.round(breakEvenHours / 8 * 10) / 10} day{breakEvenHours / 8 !== 1 ? 's' : ''} of billable work!
                </p>
              )}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default BillableHoursCalculator;