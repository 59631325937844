import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import BlogCard from "components/BlogCard";
import WhatsNewCard from "components/cards/WhatsNewCard";
import HelpBanner from "components/HelpBanner";
import HowToUseJuricon from "components/HowToUseJuricon";
import NewsletterSubscriptionPopup from "components/NewsletterSubscriptionPopup"; // Import the popup component
import GeneralSearch from "components/search/Search";
import { Testimonial2 } from "components/testimonialsConsumers/TestmonialsConsumer";
import VideoSection from "components/VideoSection";

import subscribeIcon from "assets/subscribe.svg"; // Assuming the icon is located here
import { isCalAtomsUserIDValid } from "utils/config";
import {
  getPinnedBlogPosts,
  getSeoSettingsForPage,
} from "../sanity/lib/sanityClient";

const Home = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [seoData, setSeoData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const userType = user?.userType;
  const isProvider =
    userType === "PROVIDER" &&
    isCalAtomsUserIDValid(user?.calAtomsUserId) &&
    user?.subscription;

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "Home page" });
    getPinnedBlogPosts().then(setBlogPosts).catch(console.error);
  }, []);

  useEffect(() => {
    getSeoSettingsForPage("Home").then(setSeoData).catch(console.error);
  }, []);

  const redirectUrl = new URLSearchParams(location.search).get("from");
  if (user && redirectUrl && redirectUrl !== "/") {
    return <Navigate to={redirectUrl} />;
  }

  return (
    <div>
      {seoData && (
        <Helmet>
          <title>{seoData.metaTitle}</title>
          <meta name="description" content={seoData.metaDescription} />
          <meta name="keywords" content={seoData.keywords?.join(", ")} />
          <meta property="og:title" content={seoData.ogTitle} />
          <meta property="og:description" content={seoData.ogDescription} />
          {seoData.ogImage && (
            <meta property="og:image" content={seoData.ogImage} />
          )}
          <meta name="twitter:title" content={seoData.twitterTitle} />
          <meta
            name="twitter:description"
            content={seoData.twitterDescription}
          />
          {seoData.twitterImage && (
            <meta name="twitter:image" content={seoData.twitterImage} />
          )}
        </Helmet>
      )}

      <GeneralSearch />

      <HowToUseJuricon />
      <Testimonial2 />
      <VideoSection className="mb-16 px-4" />
    

      {isProvider && (
        <div className="flex items-center justify-center p-4 md:p-4">
          <WhatsNewCard />
        </div>
      )}

      <div className="px-4 mx-auto mt-18 max-w-7xl">
        {/* Center aligned container */}
        <div className="flex flex-col items-center mb-12 text-center">
          {/* Title and subscribe icon wrapper */}
          <div className="flex items-center justify-center mb-4 space-x-2">
            <h1 className="text-5xl font-bold text-secondary">
              The Legal Lowdown
            </h1>
            <img
              src={subscribeIcon}
              alt="Subscribe"
              className="w-20 h-20 cursor-pointer"
              onClick={() => setShowPopup(true)}
            />
          </div>

          {/* Subtitle */}
          <h2 className="max-w-3xl text-2xl text-primary">
            Articles, Blogs, News, and Guides You Need to Stay in the Know.
          </h2>
        </div>

        {/* Blog grid would go here */}
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          {blogPosts.map((post) => (
            <BlogCard
              key={post._id}
              title={post.title}
              excerpt={post.excerpt}
              mainImage={post.mainImage}
              slug={post.slug.current}
            />
          ))}
        </div>

        <div className="flex justify-center mt-8">
          <button
            onClick={() => navigate("/blog")}
            className="px-6 py-2 mt-10 mb-10 font-bold text-white transition rounded-md bg-primary hover:bg-secondary"
          >
            Explore More
          </button>
        </div>
      </div>

      {/* Newsletter Subscription Popup */}
      {showPopup && (
        <NewsletterSubscriptionPopup
          showPopup={showPopup}
          setShowPopup={setShowPopup}
        />
      )}
    
      <div className="flex items-center justify-center p-4 md:p-4">
        <HelpBanner />
      </div>
    </div>
  );
};

export default Home;
