import GeneralSearch from "components/search/GeneralSearch";
import SearchLayout from "components/search/SearchLayout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetSearchReducer } from "store/actions/actions";

const Search = () => {
  const { searchType } = useSelector((state) => state.searchReducer);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(resetSearchReducer());
  }, []);
  
  return (
    <SearchLayout>
      <GeneralSearch />
    </SearchLayout>
  );
};

export default Search;
