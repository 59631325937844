const VideoSection = ({ className }) => {
  const vimeoEmbedUrl = "https://player.vimeo.com/video/1027990739";

  return (
    <div className={className}>
      <div className="max-w-3xl mx-auto">
        <div className="relative overflow-hidden bg-gray-100 rounded-lg aspect-video">
          <iframe
            className="absolute inset-0 w-full h-full"
            src={vimeoEmbedUrl}
            title="Service Overview"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default VideoSection; 